<template>
    <div>
        <section class="section section-lg">
            <div class="container">
                <!-- Page title -->
                <div class="col-lg-12 text-center">
                    <h2 class="display-3 title">{{ title }}</h2>
                </div>
                <!-- Page title -->

                <!-- Page content -->
                <div class="col-lg-12">
                    <img 
                        v-for="(image, i) in images" 
                        :key="i" 
                        class="image" 
                        :src="image" 
                        @click="index = i"
                    ><br><br>
                    <vue-gallery-slideshow
                        :images="images" 
                        :index="index" 
                        @close="index = null"
                    />
                </div>
                <!-- Page content -->
            </div>
        </section>
    </div>
</template>

<script>
import axios from 'axios';
import VueGallerySlideshow from 'vue-gallery-slideshow';

export default {
    components: {
        VueGallerySlideshow
    },
    data () {
        return {
            index: null,
            images: [],
            title: ''
        };
    },
    beforeMount () {
        this.get_album_images();
    },
    methods: {
        // Set the headers for any API requests
        setHeaders() {
            axios.defaults.headers.common["Content-type"] = "application/x-www-form-urlencoded";
        },
        get_album_images () {
            this.setHeaders();

            axios.get(this.$apiUrl + "/get_album_images",
                {
                    params: {
                        album_id: this.$route.params.id
                    }
                }
            ).then(response => {
                this.images = response.data.Images;
                this.title = response.data.AlbumName;
            });
        }
    }
};
</script>

<style scoped>
    .title {
        padding-bottom: 40px;
    }

    .image {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        border: 1px solid #ebebeb;
        margin: 5px;
        width: 300px;
        height: 200px;
    }
</style>
